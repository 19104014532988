import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SelectTheme } from "../../redux/slice/themeSlice";
import { Link, useParams } from "react-router-dom";
import "./productDetails.css";
import { SET_CURRENT_URL, SelectProducts } from "../../redux/slice/productSlice";
import { useEffect } from "react";
import { motion } from "framer-motion";
import BreadCrumps from "../../components/breadCrumps/BreadCrumps";
import Form from "../../components/form/Form";
const ProductDetails = () => {
  const dispatch = useDispatch();
  const theme = useSelector(SelectTheme);
  const product = useSelector(SelectProducts);
  const [contactForm, setContactForm] = useState(false);
  //  const [type, setType] = useState( window.location.pathname.includes('Schuine') ? 'schuine' : 'valk')

  const { id, type } = useParams();

  const currentProduct =
    type === "Schuine-Type"
      ? product.schuine?.find((product) => product.id === id)
      : product.vlak?.find((product) => product.id === id);

  useEffect(() => {
    console.log(type);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);


// useEffect(() => {
// const links =  document.querySelectorAll('.data-row')
// links.forEach(link => { 






// })
// }, [])


useEffect(() => {
  dispatch(
    SET_CURRENT_URL(window.location.pathname)
  )


}, [])





  return (
    <div className="product-details p-2">
      <div className="container d-flex flex-column " style={{ minHeight: "calc(100vh - 195px)" }}>
        {contactForm ? <Form id={id} setContactForm={setContactForm} /> : null}
        <BreadCrumps />
        <div className="row h-100 m-0">
          <motion.div
            initial={{ x: "-100vh" }}
            animate={{ x: 0, transition: { duration: 0.8, ease: "easeInOut" } }}
            className="current-image-container col-lg-6 col-md-6 col-sm-12"
          >
            <h1 className="name">
              {" "}
              <b> Staalix® MATSPRINCK {currentProduct?.name} </b>{" "}
            </h1>
            <div className="position-relative">
              <img
                className="current-image"
                src={currentProduct?.image}
                alt={currentProduct?.model}
              />
              <h1 className="vertical">{currentProduct?.model}</h1>
            </div>
          </motion.div>
          <motion.div
          
            initial={{ x: "100vh", opacity: 0 }}
            animate={{
              x: 0,
              opacity: 1,
              transition: { duration: 0.8, ease: "easeInOut" },
            }}
            className=" product-desc col-lg-6 col-md-6 col-sm-12"
          >

            <button
              type="button"
              color="primary"
              onClick={() => setContactForm(true)}
              className="product-button mt-4"
            >
              Offerte
            </button>
            <p>- Handmatig type, geschikt voor situaties waarbij een hoog koppel vereist is, maar er beperkte stroomvoorziening of ruimte is.</p>
            <p>- Geavanceerd planetaire tandwielensysteem voor koppelvermenigvuldiging, nauwkeurigheid ±5%.</p>
            <p>- Uitgerust met een anti-terugdraaimechanisme voor meer gemak en veiligheid.</p>
            <p>- Eenvoudig overschakelen van de klok mee- naar de tegen de klok in richting met behulp van de omkeerhendel en knoppen.</p>
            <p>- After-sales service: 1 jaar garantieperiode.</p>
            <p>- Leveringsomvang: 1 stuk handmatige koppelvermenigvuldiger, 1 stuk reactiearm, 1 set bedieningsinstructies, 1 exemplaar van het testrapport.</p>
          </motion.div>
        </div>
        {type === "Schuine-Type" ? 
        <iframe style={{maxWidth:"100%"}} className="mt-2" height={500} src="https://sergiowrench.netlify.app/"></iframe>
      : null  
      }
        <div className="table-container mt-5">
<h4>Vlakke-Type technische parameters</h4>
          <table className="tablee ">
            <thead>
              <tr>
                <th>Model</th>
                <th>Uitgaand koppel</th>
                <th>Accuracy</th>
                <th>Overbrengingsverhouding</th>
                <th>Ingang</th>
                <th>Uitgang</th>
                <th>Dimensies</th>
                <th>Bout diameter</th>
                <th>Moer tegenoverliggende kant</th>
                <th>Gewicht</th>
              </tr>
            </thead>
            <tbody>
              {product?.vlak?.map((product, i) => (
                <tr className={` ${product.type === type && product.id === id ? 'current-page' : 'product-row'} `}>
           <td><Link to={`/catalogus/Vlakke-Type/${product.id}`} className="table-links">{product.model}</Link></td>
            <td><Link to={`/catalogus/Vlakke-Type/${product.id}`} className="table-links">{product['Uitgaand koppel']}</Link></td>
            <td><Link to={`/catalogus/Vlakke-Type/${product.id}`} className="table-links">±5%</Link></td>
            <td><Link to={`/catalogus/Vlakke-Type/${product.id}`} className="table-links">{product.Overbrengingsverhouding}</Link></td>
            <td><Link to={`/catalogus/Vlakke-Type/${product.id}`} className="table-links">{product.Ingang}</Link></td>
            <td><Link to={`/catalogus/Vlakke-Type/${product.id}`} className="table-links">{product.Uitgang}</Link></td>
            <td><Link to={`/catalogus/Vlakke-Type/${product.id}`} className="table-links">{product.Dimensies}</Link></td>
            <td><Link to={`/catalogus/Vlakke-Type/${product.id}`} className="table-links">{product['Bout diameter']}</Link></td>
            <td><Link to={`/catalogus/Vlakke-Type/${product.id}`} className="table-links">{product['Moer tegenoverliggende kant']}</Link></td>
            <td><Link to={`/catalogus/Vlakke-Type/${product.id}`} className="table-links">{product.Gewicht}</Link></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="table-container mt-5">
<h4>Schuine-Type technische parameters</h4>
          <table className="tablee ">
            <thead>
              <tr>
                <th>Model</th>
                <th>Uitgaand koppel</th>
                <th>Accuracy</th>
                <th>Overbrengingsverhouding</th>
                <th>Ingang</th>
                <th>Uitgang</th>
                <th>Dimensies</th>
                <th>Bout diameter</th>
                <th>Moer tegenoverliggende kant</th>
                <th>Gewicht</th>
              </tr>
            </thead>
            <tbody>
              {product?.schuine?.map((product, i) => (
                <tr className={` ${product.type === type && product.id === id ? 'current-page' : 'product-row'} `}>
           <td><Link to={`/catalogus/Schuine-Type/${product.id}`} className="table-links">{product.model}</Link></td>
            <td><Link to={`/catalogus/Schuine-Type/${product.id}`} className="table-links">{product['Uitgaand koppel']}</Link></td>
            <td><Link to={`/catalogus/Schuine-Type/${product.id}`} className="table-links">±5%</Link></td>
            <td><Link to={`/catalogus/Schuine-Type/${product.id}`} className="table-links">{product.Overbrengingsverhouding}</Link></td>
            <td><Link to={`/catalogus/Schuine-Type/${product.id}`} className="table-links">{product.Ingang}</Link></td>
            <td><Link to={`/catalogus/Schuine-Type/${product.id}`} className="table-links">{product.Uitgang}</Link></td>
            <td><Link to={`/catalogus/Schuine-Type/${product.id}`} className="table-links">{product.Dimensies}</Link></td>
            <td><Link to={`/catalogus/Schuine-Type/${product.id}`} className="table-links">{product['Bout diameter']}</Link></td>
            <td><Link to={`/catalogus/Schuine-Type/${product.id}`} className="table-links">{product['Moer tegenoverliggende kant']}</Link></td>
            <td><Link to={`/catalogus/Schuine-Type/${product.id}`} className="table-links">{product.Gewicht}</Link></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
