import { useEffect, useState } from 'react';
import Nav from './components/nav/Nav';
import Loader from './components/loader/Loader';
import Home from './pages/home/Home';
import { Route , Routes , useLocation  } from 'react-router-dom';
import Footer from './components/footer/Footer';
import { AnimatePresence } from 'framer-motion';
import Catalogus from './pages/catalogus/Catalogus';
import ProductDetails from './pages/productDetails/ProductDetails';
import { SET_PRODUCTS } from './redux/slice/productSlice';
import { useDispatch, useSelector } from 'react-redux';
import Overons from './pages/overons/Overons';
import {motion } from 'framer-motion'
import Dealers from './pages/dealers/Dealers';
import data from './data.json'
import Schuine from './pages/schuine/Schuine';
import Vlak from './pages/vlak/Vlak'
function App() {
const dispatch = useDispatch();
  const [loading, setLoading] = useState(window.location.pathname ? true : false);
  const [mainDiv, setMainDiv] = useState(false)
const location = useLocation();


const vlak = data.vlak
const schuine = data.schuine




  
  // const products = [
  //   {
  //     image: AST200,
  //     name: "Momentvermeerderaar 20000 Nm",
  //     id: "Momentvermeerderaar20000Nm",
  //     model: "AST-200B",
  //     "Uitgaand koppel": 20000,
  //     Overbrengingsverhouding: "1:76",
  //     Ingang: 1 / 2,
  //     Uitgang: 2 - 1 / 2,
  //     Dimensies: "170x264x(163.75-276.25)mm",
  //     "Bout diameter": "M72-100",
  //     "Moer tegenoverliggende kant": "S105-145",
  //     Gewicht: "27.8kg",
  //     desc1:"Laat uw verwachtingen overschrijden door de onbegrensde mogelijkheden van de Staalix® MATSPRINCK Momentvermeerderaar 20000 Nm, een buitengewoon product uit onze spraakmakende MATSPRINCK-serie. Deze momentvermeerderaar belichaamt de perfecte combinatie van innovatie, betrouwbaarheid en indrukwekkende prestaties. Of je nu betrokken bent bij zware constructies, machinewerk of andere uitdagende taken, de Staalix® MATSPRINCK Momentvermeerderaar 20000 Nm tilt jouw industriële projecten naar ongekende hoogten.",
  //     desc2:"Met een ongeëvenaard koppel van 20.000 Newtonmeter biedt deze momentvermeerderaar buitengewone kracht en nauwkeurigheid. Hij is ontworpen om bouten en moeren met gemak vast te draaien, waardoor je tijd bespaart en fouten minimaliseert. Deze krachtige tool uit onze nieuwe MATSPRINCK-serie verbetert niet alleen jouw werk, maar ook de efficiëntie van jouw projecten.",
  //     desc3:"Naast zijn ongeëvenaarde prestaties biedt de Staalix® MATSPRINCK Momentvermeerderaar 20000 Nm duurzaamheid en ergonomie. Het ergonomische ontwerp zorgt voor een comfortabele grip en optimale controle, zelfs bij langdurig gebruik. Gemaakt van hoogwaardig staal, is dit product ontworpen om langdurige prestaties te leveren, zelfs onder de meest veeleisende werkomstandigheden.",
  //     desc4:"Ervaar zelf de voordelen van de Staalix® MATSPRINCK Momentvermeerderaar 20000 Nm. Deze betrouwbare, precieze en veelzijdige tool voldoet aan de hoogste professionele normen. Bestel vandaag nog en ontdek waarom professionals wereldwijd vertrouwen op de kwaliteit en prestaties van de producten uit onze nieuwe serie.",
  //   },
  //   {
  //     image: AST150,
  //     name: "Momentvermeerderaar 15000 Nm",
  //     id: "Momentvermeerderaar15000Nm",
  //     model: "AST-150B",
  //     "Uitgaand koppel": 15000,
  //     Overbrengingsverhouding: "1:56",
  //     Ingang: 1 / 2,
  //     Uitgang: 2 - 1 / 2,
  //     Dimensies: "156x246x(155.25-244.75)mm",
  //     "Bout diameter": "M60-85",
  //     "Moer tegenoverliggende kant": "S80-120",
  //     Gewicht: "24.1kg",
  //     desc1:"Ontdek de ongeëvenaarde kracht van de Staalix® MATSPRINCK Momentvermeerderaar 15000 Nm uit onze MATSPRINCK-serie. Dit geavanceerde gereedschap tilt jouw industriële projecten naar ongekende hoogten. Of je nu werkt aan zware constructies, machinewerk of andere veeleisende taken, de Staalix® MATSPRINCK Momentvermeerderaar 15000 Nm levert indrukwekkende prestaties.",
  //     desc2:"Met een krachtig koppel van 15.000 Newtonmeter biedt deze momentvermeerderaar ongeëvenaarde sterkte en precisie. Het is jouw betrouwbare partner voor het moeiteloos vastdraaien van bouten en moeren, waardoor je tijd bespaart en de nauwkeurigheid verbetert. De Staalix® MATSPRINCK Momentvermeerderaar 15000 Nm staat garant voor prestaties van topklasse, zelfs bij de meest uitdagende taken",
  //     desc3:"Naast zijn kracht onderscheidt de Staalix® MATSPRINCK Momentvermeerderaar 15000 Nm zich door duurzaamheid en gebruiksgemak. Het ergonomische ontwerp biedt een meesterlijke omhelzing aan uw handen, waardoor u kunt genieten van een optimale grip en ongeëvenaarde controle, zelfs tijdens langdurig gebruik. Vervaardigd met hoogwaardige materialen, levert dit gereedschap langdurige prestaties, zelfs onder zware werkomstandigheden.",
  //     desc4:"Ervaar de ongeëvenaarde prestaties van de Staalix® MATSPRINCK Momentvermeerderaar 15000 Nm en til jouw industriële projecten naar een hoger niveau. Dit veelzijdige en nauwkeurige gereedschap voldoet aan de hoogste professionele normen. Vertrouw op de kwaliteit en betrouwbaarheid van de Staalix® MATSPRINCK Momentvermeerderaar 15000 Nm en behaal uitstekende resultaten."
  //   },
  //   {
  //     image: AST100,
  //     name: "Momentvermeerderaar 10000 Nm",
  //     id: "Momentvermeerderaar10000Nm",
  //     model: "AST-100B",
  //     "Uitgaand koppel": 10000,
  //     Overbrengingsverhouding: "1:28",
  //     Ingang: 1 / 2,
  //     Uitgang: 2 - 1 / 2,
  //     Dimensies: "141x186x(144.05-215.95)mm",
  //     "Bout diameter": "M52-72",
  //     "Moer tegenoverliggende kant": "S80-105",
  //     Gewicht: "15.2kg",
  //     desc1:"Betreed de wereld van ongeëvenaarde kracht en precisie met de Staalix® MATSPRINCK Momentvermeerderaar 10000 Nm. Dit voortreffelijke gereedschap uit onze prestigieuze MATSPRINCK-serie opent nieuwe horizonten in de industriële sector. Of u nu werkt aan monumentale constructies, complexe machinewerken of uitdagende projecten, de Staalix® Momentvermeerderaar MATSPRINCK 10000 Nm is een meesterwerk van prestatie en vakmanschap.",
  //     desc2:"Met een indrukwekkend koppel van 10.000 Newtonmeter biedt deze momentvermeerderaar de krachtige precisie die uw projecten naar een hoger niveau tilt. Het vastdraaien van bouten en moeren wordt een vlotte handeling, waardoor uw werk efficiënter en nauwkeuriger verloopt. Als een verfijnde krachtpatser uit de MATSPRINCK-serie, is de Staalix® MATSPRINCK Momentvermeerderaar 10000 Nm uw betrouwbare metgezel, zelfs bij de meest veeleisende taken.",
  //     desc3:"De Staalix® MATSPRINCK Momentvermeerderaar 10000 Nm onderscheidt zich echter niet alleen door zijn kracht. Het is een toonbeeld van duurzaamheid en comfort. Het ergonomische ontwerp omarmt uw handen als een instrument van meesterschap, waardoor u optimale grip en controle heeft, zelfs bij langdurig gebruik. Met behulp van hoogwaardige materialen en ongeëvenaard vakmanschap is deze momentvermeerderaar ontworpen om langdurige topprestaties te leveren, zelfs in de meest veeleisende werkomgevingen.",
     
    
  //   },
  // ];

  useEffect(() => {
    // if(window.location.pathname === '/'){

    //   setLoading(true);
    // }
    setTimeout(() => {
      setLoading(false);
    }, 4200);
    setTimeout(() => {
      setMainDiv(true)
    }, 4100);
    
  }, []);





  useEffect(() => {
    console.log(data)
   dispatch(
    SET_PRODUCTS({
      products : data
    })
   )
  }, [dispatch , vlak])











  return (
    
    <div className='app'>
      {loading ? <Loader/> : null}
      {mainDiv ? (

        <motion.div
        initial={{opacity:0}}
        animate={{opacity:1 , transition:{duration : 0.8}}}
        >
      <Nav/>
      <AnimatePresence mode='wait' >
      <Routes location={location} key={location.key}>
<Route path="/" element={<Home/>} />
<Route path="/catalogus" element={<Catalogus/>} />
<Route path="/catalogus/:type/:id" element={<ProductDetails/>} />
<Route path="/overons" element={<Overons/>} />
<Route path="/dealers" element={<Dealers/>} />
<Route path="/catalogus/Schuine-Type" element={<Schuine/>} />
<Route path="/catalogus/Vlakke-Type" element={<Vlak/>} />
      </Routes>
      </AnimatePresence>
      <Footer/>
      </motion.div>
      ): null }
     
      
    </div>
  );
}

export default App;
