import React, { useState } from 'react'
import './nav.css'
import { motion } from 'framer-motion';
import logodark from '../../assets/png.png'
import logolight from '../../assets/Vector file.svg'
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import {ImMenu3 , ImMenu4} from 'react-icons/im';
import { useDispatch  } from 'react-redux';
import { CHANGE_THEME } from '../../redux/slice/themeSlice';
const Nav = () => {




  const cuurentUrl = window.location.pathname.includes('catalogus');
  const [language, setLanguage] = useState('dutch')
  const [openMenu, setOpenMenu] = useState(false)
  const dispatch = useDispatch()

const [theme, setTheme] = useState('dark')

    const draw = {
        hidden: {y: '50%' , x:'50%' ,  opacity: 1 },
        visible:  {
            x:0,
            y:0,
            opacity: 1,
            width:'200px' , 
    height:'200px',
            transition: {
              opacity: { duration: 2 },
              y: { delay:4 ,  duration: 3 },
              x: { delay:4 ,  duration: 3 },
            }
        }
      };
      
useEffect(() => {
const currentTheme = localStorage.getItem('current-theme')
console.log(currentTheme);
if(currentTheme === 'light'){
setTheme('light')
document.documentElement.style
.setProperty('--main-color', 'white');
document.documentElement.style
 .setProperty('--main-text-color', 'black');
document.documentElement.style
 .setProperty('--hero-text-color', 'black');
 document.documentElement.style
  .setProperty('--cube-hover-bgtop', '#ebe9e2');
  document.documentElement.style
  .setProperty('--cube-bgtop', '#28282d');
  document.documentElement.style
  .setProperty('--bg-inner', '#28282d');

}else if(currentTheme === 'dark'){



  setTheme('dark')



  
  document.documentElement.style
  .setProperty('--main-color', 'black');
  document.documentElement.style
  .setProperty('--main-text-color', 'white');
  document.documentElement.style
  .setProperty('--hero-text-color', 'white');
 

  document.documentElement.style
  .setProperty('--cube-hover-bgtop', '#28282d');
  document.documentElement.style
  .setProperty('--cube-bgtop', '#ebe9e2');
  document.documentElement.style
  .setProperty('--bg-inner', ' #ebe9e2');

}
  
}, [])


useEffect(() => {
  dispatch(
    CHANGE_THEME({
      currentTheme: theme
    })
  )
}, [theme])



const handleThemeChange = () => {
  const changeTheme = theme === 'dark'? 'light' : 'dark'
setTheme(changeTheme)

if(changeTheme === 'light'){









  

  document.documentElement.style
  .setProperty('--main-color', 'white');
  document.documentElement.style
   .setProperty('--main-text-color', 'black');
   document.documentElement.style
   .setProperty('--hero-text-color', 'black');
  
   document.documentElement.style
   .setProperty('--cube-hover-bgtop', '#ebe9e2');
   document.documentElement.style
   .setProperty('--cube-bgtop', '#28282d');
   document.documentElement.style
   .setProperty('--bg-inner', '#28282d');


  
}else if(changeTheme === 'dark'){

  document.documentElement.style
  .setProperty('--main-color', 'black');
  document.documentElement.style
  .setProperty('--main-text-color', 'white');
 





  document.documentElement.style
  .setProperty('--hero-text-color', 'white');
 
 
   document.documentElement.style
   .setProperty('--cube-hover-bgtop', '#28282d');
   document.documentElement.style
   .setProperty('--cube-bgtop', '#ebe9e2');
   document.documentElement.style
   .setProperty('--bg-inner', ' #ebe9e2');

}
console.log(changeTheme);
localStorage.setItem('current-theme', changeTheme)

}


  return (
    <div className={`navv    p-4 `}>

    <div className='navaigation-container container d-flex align-items-center justify-content-between'>


    <Link to='/'>
 <img  className='staalix-logo logolight' src={logodark} alt='Stallix'/> 
  
    </Link>
   
    <label className="switch">
  <input checked={theme === 'dark' ? false : true } onChange={handleThemeChange}  type="checkbox"/>
  <span className="slider"></span>
</label>

    </div>


    </div>
  )
}

export default Nav