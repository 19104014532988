import { configureStore , combineReducers, getDefaultMiddleware } from "@reduxjs/toolkit";
import themeReducer from "./slice/themeSlice";
import productReducer from "./slice/productSlice";



const rootReducer = combineReducers({
    theme: themeReducer,
    products: productReducer
})


const store = configureStore({
    reducer : rootReducer,
middleware : (getDefaultMiddleware)=> getDefaultMiddleware({serializableCheck: false}),



})


export default store