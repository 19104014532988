import React, { useEffect } from 'react'
import './overons.css'
import { motion } from 'framer-motion'
import BreadCrumps from '../../components/breadCrumps/BreadCrumps'
import { SET_CURRENT_URL } from '../../redux/slice/productSlice'
import { useDispatch } from 'react-redux'
const Overons = () => {
  const dispatch = useDispatch()
useEffect(() => {
  dispatch(
    SET_CURRENT_URL(window.location.pathname)
  )
}, [])
  return (
    <div className='overons pt-5'>
<div style={{minHeight:'calc(100vh - 194px)'}} className='container'>
    <BreadCrumps/>
<motion.div 
  initial={{opacity:0}}
  animate={{opacity:1 , transition: {duration: 0.4}}}
className='row gx-3 m-0'>
    <div className='d-flex justify-content-center text-end ps-2 col-lg-6 col-md-6 col-sm-12'>
        <h1 
      
        className='text-center'>Over Ons</h1>
       <p className='text-start ps-2'>
       In de wereld van industriële kracht en precisie is er één naam die synoniem staat voor excellentie: Staalix®. Als producent en dochteronderneming van Sertic, zijn wij gespecialiseerd in de productie van momentgereedschappen die de normen verleggen.<br/><br/>
       Bij Staalix® streven we voortdurend naar innovatie en vakmanschap. Onze momentgereedschappen zijn ontworpen om de grenzen van prestaties te verleggen, waarbij elk detail zorgvuldig wordt vormgegeven om de meest veeleisende taken moeiteloos aan te kunnen.<br/><br/>

        </p> 

    </div>
    <div className=' ps-2 trots   col-lg-6 col-md-6 col-sm-12'>
        <p className='m-0'>
        We presenteren met trots een klein scala aan momentgereedschappen, met onze specialiteit in de buitengewone momentvermeerderaars. Deze krachtige apparaten tillen projecten naar ongekende hoogten, met indrukwekkende koppels die elke uitdaging aankunnen. <br/><br/>
        We geloven dat kwaliteit en betrouwbaarheid de hoekstenen zijn van elk succesvol project. Daarom worden al onze gereedschappen vervaardigd met behulp van hoogwaardige materialen en geavanceerde technologieën, om duurzaamheid en topprestaties te garanderen.<br/><br/>
        Onze bedrijfscultuur ademt excellentie, van onze gedreven teamleden tot onze ongeëvenaarde klantenservice. We streven voortdurend naar het vervullen van de behoeften en verwachtingen van onze klanten, en we leggen de basis voor samenwerkingen die gebaseerd zijn op vertrouwen en gezamenlijk succes. <br/><br/>
</p>
    </div>
</motion.div>
<motion.p 
  initial={{opacity:0}}
  animate={{opacity:1 , transition: {duration: 0.4}}}
className='px-2'>
Staalix®, waar vakmanschap en innovatie samenkomen, is de toonaangevende keuze voor professionals die streven naar optimale prestaties in elke industriële omgeving. Ontdek de kracht van onze momentgereedschappen en ervaar de Staalix-kwaliteit die uw projecten naar nieuwe hoogten tilt.

</motion.p>





</div>


    </div>
  )
}

export default Overons