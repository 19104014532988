import React, { useEffect, useState } from 'react'
import './catalogus.css'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import Products from '../../components/products/Products'
import gifDark from "../../assets/Black Modern Space Channel Video (1).gif"
import gifLight from "../../assets/white Modern Space Channel Video (1).gif"
import { useSelector } from 'react-redux'
import { SelectTheme } from '../../redux/slice/themeSlice'
import BreadCrumps from '../../components/breadCrumps/BreadCrumps'
import vlak from '../../assets/types/3500vlak.jpg'
import schuine from '../../assets/types/5500schuin.jpg'
import { SelectCurrentUrl } from '../../redux/slice/productSlice'


const Catalogus = () => {
const [showTypes, setShowTypes] = useState(false)
const [type, setType] = useState('')
  const [gif, setGif] = useState(true);
const theme = useSelector(SelectTheme)
const currentUrl = useSelector(SelectCurrentUrl)
const types = [
  {
image : vlak , 
type: "Vlakke-Type"


  },
  {
image : schuine , 
type: "Schuine-Type"


  },
]




    const variant = {
        hidden:{
            opacity:0
        },
        visible:{
          opacity:1,
          transition:{ duration:2  , ease:"easeInOut"}
        },
        exit:{
          opacity:'0',
          transition:{ease:"easeInOut"}
          
          
          }
      }
      useEffect(() => {
        if(currentUrl !== "/"){

          setGif(false)
        }
        setTimeout(() => {
          setGif(false)
        }, 2800);
      }, [])
  return (
    <motion.div 
    variants={variant}
    initial='hidden'
    animate='visible'
    exit='exit'
    className='catalogus-container' style={{minHeight:'calc(100vh - 195px)'}}>
 {gif? (   

<img className="gif" src={theme === 'dark' ? gifDark : gifLight } alt="MATSPRINCK SERIES" />


           ):
           
           
           
           
           <>

        
        <BreadCrumps/>

<div className="products-container ">
      <div className="row gap-4 justify-content-center m-0">
       
        {types?.map((product, index) => (
          <Link
          to={`/catalogus/${product.type}`}
          // onClick={() => handleTypes(product.type)}
            key={index}
            className=" product-card flex-wrap col-lg-6 col-md-6 col-sm-12"
          >
            <p className="product-name ">
              <b>{product.type}</b>
            </p>
            <img src={product.image} alt={product.type} />
          </Link>
        ))}
      </div>
      </div>








           </>

            }


    </motion.div>
  )
}

export default Catalogus