import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  Products: null,
  CurrentUrl:""
};

const productSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    SET_PRODUCTS: (state, action) => {
      console.log(action.payload.products);
      state.Products = action.payload.products;
    },
    SET_CURRENT_URL: (state, action) => {
console.log(action.payload)
state.CurrentUrl = action.payload


    }
  },
});

export const { SET_PRODUCTS  , SET_CURRENT_URL} = productSlice.actions;
export const SelectProducts = (state) => state.products.Products;
export const SelectCurrentUrl = (state) => state.products.CurrentUrl;

export default productSlice.reducer;