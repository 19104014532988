import React, { useEffect } from 'react'
import './breadCrumps.css'
import { Link } from 'react-router-dom';
const BreadCrumps = () => {
    const Urls = window.location.pathname.split('/');
    // const currentPathname = window.location.href + window.location.pathname;
 const currentPageUrl = Urls[Urls.length - 1];


 
 
  return (
    <div className='mb-5 mt-4 container'>
<ul className="breadcrumb ">
{Urls?.map((url, index) => {
if(url === currentPageUrl){
  return    <p  key={index} className="current-page">{url}</p>
}else{
return    <Link key={index}  to={ url.includes('Type') ? `/catalogus/${url}` : `/${url}` }>{url ? url : 'Home'}</Link>
}
})
}
 
</ul>

    </div>
  )
}

export default BreadCrumps