import React, { useState } from "react";
import "./dealers.css";
import { Helmet } from "react-helmet";
import autodocimage from "../../assets/dealers/autodoc.png";
const Dealers = () => {
  const [showpop, setShowpop] = useState(false);
  const [popinfo, setPopinfo] = useState({});
  const dealers = [
    {
      name: "Autodoc",
      image: autodocimage,
      link: "https://www.autodoc.nl/",
      description: `Met onze diensten willen we de mobiliteit waarborgen van degenen die zich individueel verplaatsen met auto, motorfiets of vrachtwagen. Individuele mobiliteit is een pijler van de moderne samenleving. We zetten ons in om de mobiliteit van onze klanten te waarborgen en hun voertuigen langer bruikbaar te maken. Dit bespaart hulpbronnen en is dankzij onze diensten comfortabel en kostenbesparend. Ons uitgebreide assortiment auto-onderdelen biedt onze klanten een ruime keuze tegen aantrekkelijke prijzen. Met tips, video-tutorials en expert-chats stellen we hen ook in staat om kleine reparaties zelf uit te voeren. Voor complexere reparaties zorgen onze partnerschappen met geselecteerde werkplaatsen voor transparantie en eerlijke dienstverlening.`,
    },
  ];
  let popup;
  const handlepopup = (name, image, description, link) => {
    setPopinfo({ name, image, description, link });

    setShowpop(true);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Staalix:Dealers</title>
        <meta name="description" content="Staalix.nl - Dealers" />
      </Helmet>
      <div className="dealers">
        {showpop && (
          <div className="dealerpopup">
            <div className="dealerinfo">
              <div className="dealercontent">
                <p>{popinfo.name}</p>
                <img src={popinfo.image} alt={popinfo.name} />
              </div>
              <div className="text-center">
                <p>{popinfo.description}</p>
                <a
                  href={popinfo.link}
                  target="_blank"
                  className="btn btn-secondary"
                >
                  bezoek {popinfo.name}
                </a>
                <button
                  className="btn btn-danger ms-3"
                  onClick={() => setShowpop(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
        <div
          style={{ minHeight: "calc(100vh - 199px )" }}
          className="dealers-box container"
        >
          {dealers.map((dealer, index) => {
            const { name, image, link, description } = dealer;
            return (
              <div
                className="thedealer"
                onClick={() => handlepopup(name, image, description, link)}
              >
                <h2 className="number">{index + 1}</h2>
                <div className="dealercontent">
                  <p>{dealer.name}</p>
                  <img src={dealer.image} alt={dealer.name} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Dealers;
