import React, { useState } from 'react'
import './hero.css'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import Contact from '../contact/Contact'
import { useDispatch } from 'react-redux'
import { SET_CURRENT_URL } from '../../redux/slice/productSlice'
const Hero = () => {
  const dispatch = useDispatch()
  const variant = {
    exit:{
      opacity:'0',
      transition:{duration:0.8,  ease:"easeInOut"}
      
      
      }
  }

  const [isopen,setisopen]=useState(false)
  const [close, setclose] = useState(false)
const openEmailPopUp = () => {
  setclose(false)
setisopen(true)
}

const handleAnimation = () => {
dispatch(SET_CURRENT_URL(window.location.pathname))


}

  return (
    <motion.div variants={variant} exit='exit' className='hero-container'>
      < Contact isopen={isopen} setisopen={setisopen} close={close} setclose={setclose}/>
    <div className='container'>
      
   <div  className='gear'>
    <i></i><i></i>
</div>
        <div className='d-flex align-items-center justify-content-center mb-5 flex-wrap'>

<Link to='/catalogus' onClick={handleAnimation} className='btnn cube cube-hover'>
    
<div className="bg-top">
  <div className="bg-inner"></div>
  </div>
  <div className="bg-right">
  <div className="bg-inner"></div>
  </div>
  <div className="bg">
  <div className="bg-inner"></div>
  </div>
  <div className="text">Catalogus</div>  
    </Link>

<Link to='/dealers ' className='btnn cube cube-hover'>
    
    
    
      
<div className="bg-top">
  <div className="bg-inner"></div>
  </div>
  <div className="bg-right">
  <div className="bg-inner"></div>
  </div>
  <div className="bg">
  <div className="bg-inner"></div>
  </div>
  <div className="text">Dealers</div> 
    
    
    
    
    
    
    
    </Link>

<Link to='/overons ' className='btnn cube cube-hover'>


  
<div className="bg-top">
  <div className="bg-inner"></div>
  </div>
  <div className="bg-right">
  <div className="bg-inner"></div>
  </div>
  <div className="bg">
  <div className="bg-inner"></div>
  </div>
  <div className="text">Over Ons</div> 






</Link>

<button onClick={openEmailPopUp} className='btnn cube cube-hover'>

  
<div className="bg-top">
  <div className="bg-inner"></div>
  </div>
  <div className="bg-right">
  <div className="bg-inner"></div>
  </div>
  <div className="bg">
  <div className="bg-inner"></div>
  </div>
  <div className="text">Contact</div> 





</button>




    </div>
    </div>



    </motion.div>
  )
}

export default Hero