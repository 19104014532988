import React, { useState } from 'react'
import "./form.css"
import {AiOutlineCloseCircle} from "react-icons/ai"
import { motion } from 'framer-motion'
import axios from "axios";
import  toast  from 'react-hot-toast';

const Form = ({id , setContactForm}) => {

const Formanimate = {
hidden:{
  scale:0
},
visible:{
     scale:1

,
  transition:{duration:1}
},
exit:{
  scale:0
,
transition:{ease:"easeInOut"}


}
}

const [email, setEmail] = useState('')

const [companyName, setCompanyName] = useState('')












// const  handleSubmit = async (e) => {
// e.preventDefault()
// const data = {
//   email,
//   companyName,
//   id
// };

// const response = await axios.post(
//   "https://staalix-backend.adaptable.app/api/sendemail",
//   data
// );
// console.log(response.data);

// }

const handleSubmit = async (e) => {
  e.preventDefault();
  const data = {
    email,
    companyName,
    id
  };

  try {
    const response = await axios.post(
      "https://staalix-backend.adaptable.app/api/sendemail",
      data
    );
    console.log(response.data);
    toast.success("E-mail is succesvol verzonden!")
    setContactForm(false)
  } catch (error) {
    console.error(error);
    toast.error("E-mail verzenden mislukt!")
  }
};












  return (
    <div className='contact'>
        <div className='container d-flex justify-content-center py-4'>

     
     <motion.div className="login-box position-relative "
     
     variants={Formanimate}
     initial='hidden'
     animate='visible'
     exit='exit'
     >
        <AiOutlineCloseCircle onClick={() => setContactForm(false)} className='back-arrow' size={40}/>
  <h4 className='mb-5'>Details</h4>
  <form onSubmit={ handleSubmit}>
    <div className="user-box">
      <input value={companyName} onChange={(e) => setCompanyName(e.target.value)} type="text" name="id" required/>
      <label>Bedrijfsnaam</label>
    </div>
    <div className="user-box">
      <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" name="" required/>
      <label>E-mailadres</label>
    </div>
    <button type='submit' >
    
      Offerte Aanvragen
    </button>
  </form>
</motion.div>     
</div>
</div>
  )
}


export default Form