import React,{useEffect} from 'react'
import './contact.css'
import logo from '../../assets/Vector file.svg'
import { animate, delay, motion ,useCycle } from 'framer-motion'
import { AiOutlineClose } from 'react-icons/ai'
import logolight from '../../assets/Vector file.svg'
import { useSelector } from 'react-redux'
import { SelectTheme } from '../../redux/slice/themeSlice'
const Popupemail = ({isopen,setisopen,close,setclose}) => {
  const theme = useSelector(SelectTheme)
     const contentvariants = {
   open: {  y: 0 , rotate:0,padding:"10px",borderRadius:'15px',
transition:{
    y:{
        duration:1
    },
    rotate:{
    duration:1
    },
    padding:{
       delay:1 
    },
    borderRadius:{
       delay:1 
    }
    
}

},


   closed: { 
    
    y: '-100vh',
   rotate:'-180deg',
padding:0 ,
borderRadius:'50%'

},
transition:{
when:"beforeChildren",
    staggerChildren:4
}
 }
     const content2variants = {
   closed: {  y: 0 , rotate:0,padding:"10px",borderRadius:'15px',},


   open: { 
    
    y: '-100vh',
   rotate:'-180deg',
padding:0 ,
borderRadius:'50%',
transition:{
    y:{
         delay:1 ,
         ease:'easeIn'
    },
    rotate:{
     delay:1 
    }
    
    
}
},
 }
     const emailvariants = {
   hidden:{width:0 },
   visible:{width:'180px',transition:{ease:'easeInOut',delay:1}}
 }
   
  
     const closeiconvariants = {
   hidden:{display:'none' },
   visible:{display:'inline',transition:{ease:'easeInOut',delay:1}}
 }
     const imgvariants = {
   hidden:{marginRight:0 },
   visible:{marginRight:'1rem',transition:{ease:'easeInOut',delay:1}}
 }
   
 
const closeblack =()=>{
    setclose(true)
 
}
useEffect(() => {
    if(close){
  let timetoclose =setInterval(function(){

setisopen(false)


},1600)

  return () => {
    clearInterval(timetoclose)
  }
}
}, [close,setclose])


  return (
    <>
  {isopen &&
     <div className='popup-email'
     
     >
    <motion.div className='content-box d-flex align-items-center '
    variants={!close ?  contentvariants : content2variants  }
     initial='closed' 
     animate={isopen && "open" }
    //   initial={!close ? 'closed' :"open"  }
    // animate={isopen && (!close ? "open" :'closed') }
    >
          <motion.img src= { logolight}alt="staalix-logo" className='staalix_logo ' 

variants={ imgvariants}
 initial={!close ? "hidden" :"visible"  }
animate={isopen && (!close ? "visible" :"hidden") }

/>
 
    <motion.a  className='d-inline-block' href="mailto:info@staalix.nl" target="blank"
    variants={ emailvariants }
    initial={!close ? "hidden" :"visible"  }
    animate={isopen && (!close ? "visible" :"hidden") }
    
    
    ><span
    
    
    >info@staalix.nl</span> </motion.a>
    <motion.span onClick={closeblack }
    variants={ closeiconvariants}
    initial={!close ? "hidden" :"visible"  }
    animate={isopen && (!close ? "visible" :"hidden") }
    
    ><AiOutlineClose className='close-icon'  size={20}
    
    
    /></motion.span>
    
</motion.div>
</div>
 }
</>


  )
}

export default Popupemail