import React from "react";
import Hero from "../../components/hero/Hero";
import { Helmet } from "react-helmet";
const Home = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Staalix - Home</title>
        <meta name="description" content="Staalix.nl - Uw vertrouwde leverancier van hoogwaardige momentvermeerderaars en draaimomentversterkers. Optimaliseer uw industriële processen met ons veelzijdige product dat zowel als momentvermeerderaar als draaimomentversterker functioneert. Verbeter vandaag nog de efficiëntie van uw processen." />
      </Helmet>
      <div className="container">{/* <Email/> */}</div>
      <Hero />
    </div>
  );
};

export default Home;
