import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SET_CURRENT_URL, SelectProducts } from '../../redux/slice/productSlice'
import { Link } from 'react-router-dom'
import {BsArrowBarLeft} from 'react-icons/bs'
import './schuine.css'

const Schuine = () => {

    const products = useSelector(SelectProducts);
    const productType = products.schuine


    const dispatch = useDispatch()
    useEffect(() => {
      dispatch(
        SET_CURRENT_URL(window.location.pathname)
      )
    }, [])


  return (
    
<div className="products-container ">
      <div className="row gap-3 justify-content-center m-0">
      <h1 className='type-header'><Link to='/catalogus'> <BsArrowBarLeft className='previous-page-icon' /></Link>Schuine Type</h1>
       
        {productType?.map((product, index) => (
          <Link
            to={`/catalogus/Schuine-Type/${product.id}`}
            key={index}
            className=" product-card flex-wrap col-lg-3 col-md-4 col-sm-12"
          >
            <p className="product-name ">
              <b>{product.name}</b>
            </p>
            <img src={product.image} alt={product.model} />

            <div className="product-card-hover  "
            
            >
                <div className="row  flex-wrap  m-0">
                <div className="p-0 desc flex-wrap col-lg-6 col-md-6 col-sm-6">
              <p title='Model'>
                <b>Model:</b>
              </p>
              <p>{product.model}</p>
              <p title='Ingang'>
                <b>Ingang:</b>
              </p>
              <p title="Uitgang">{product.Ingang}</p>
              <p>
                <b>Uitgang:</b>
              </p>
              <p>{product.Uitgang}</p>
           

              <p title="Bout diameter">
                <b>Bout diameter:</b>
              </p>
              <p>{product['Bout diameter']}</p>


              <p title="Dimensies">
                <b>Dimensies:</b>
              </p>
              <p>{product.Dimensies}</p>

              </div>





              <div className="p-0 desc col-lg-6 col-md-6 col-sm-6">


              <p title="Overbrengingsverhouding">
                <b>Overbrengingsverhouding:</b>
              </p>
              <p>{product.Overbrengingsverhouding}</p>
              <p title="Moer tegenoverliggende kant">
                <b>Moer tegenoverliggende kant:</b>
              </p>
              <p>{product['Moer tegenoverliggende kant']}</p>
              <p title="Gewicht">
                <b>Gewicht:</b>
              </p>
              <p>{product.Gewicht}</p>

             

              <p title="Uitgaand koppel">
                <b>Uitgaand koppel:</b>
              </p>
              <p>{product['Uitgaand koppel']}</p>

              </div>
             
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>




  )
}

export default Schuine