import { createSlice } from '@reduxjs/toolkit'

const initialState = {
currentTheme: 'dark'
}

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    CHANGE_THEME: (state, action) => {
        console.log(action.payload.currentTheme);
      state.currentTheme = action.payload.currentTheme
    }
  }
});

export const {CHANGE_THEME} = themeSlice.actions
export const SelectTheme = state => state.theme.currentTheme
export default themeSlice.reducer